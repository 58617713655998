import { createContext, useReducer } from "react";
import SideMenuReducer from "./SideMenuReducer";

const INITIAL_STATE =  {
    sideMenu: true,
};

export const MenuContext = createContext(INITIAL_STATE);

export const SideMenuContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(SideMenuReducer, INITIAL_STATE);
    
    return (
        <MenuContext.Provider value = {{sideMenu: state.sideMenu, dispatch}}>
            {children}
        </MenuContext.Provider>
    )
}