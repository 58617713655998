import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../../firebase";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import { loginStart, loginSuccess, loginFailure } from "../../redux/userRedux";
import { useDispatch } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import {useForm} from 'react-hook-form'
import LoginFormvalidation from '../../Hooks/LoginFormvalidation';



const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  
  const dispatch = useDispatch();

  const navitage = useNavigate();

 
  const {HandleChange, values, errors, errorPass, setErrorPass} = LoginFormvalidation();


  // console.log("Form Errors: ", errors);

    const HandleSignin = (e) => {
        e.preventDefault();
      

      dispatch(loginStart());


    //  console.log(Object.keys(errors).length)

   
      if(Object.keys(errors).length === 0 && Object.keys(values).length === 6) {

        // https://firebase.google.com/docs/auth/web/password-auth
            signInWithEmailAndPassword(auth, values.email, values.password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            //FETCHING DATA FROM AUTHREDUCER & PASSING THE USER DATA FROM FIREBASE INTO IT
            // dispatch({type:"LOGIN", payload:user})
          //  console.log(user)

          dispatch(loginSuccess(user.email))
            navitage("/")
            })
            .catch((err) => {
              setErrorPass(true)
              dispatch(loginFailure());
            });



      }else {
        setErrorPass(true)
      }
    
    };

    // const handleData = (e) => {
    //    e.preventDefault();
    //    setLogData((prev)=> {
    //     return {...prev, [e.target.name]: e.target.value}
    //    })

    // }



 
// console.log(logData)
  return (
    <>
     {/* <Navbar/> */}
    <div className='loginContainer'>
      
        <div className="loginWrapper">
            <div className="title">SIGNIN</div>
            <form>
            {/* (e)=> setEmail(e.target.value) */}
            {/* (e)=> setPassword(e.target.value) */}
                <input name='email' minLength = '5' type="text"  placeholder='Email' onChange={HandleChange} required/>
                <input name='password' minLength = '8'type="password"  placeholder='password' onChange={HandleChange} required/>

                <button onClick={HandleSignin}>LOGIN</button>

               {/* <Link to=""  style={{ textDecoration: 'none' }} className="link">  <span>DO NOT YOU REMEMBER THE PASSWORD</span>  </Link>  */}
               {/* <Link to="/register"  style={{ textDecoration: 'none' }} className="link">  <span>CREATE A NEW ACCOUNT</span> </Link> */}
            </form>
            { errors.email && <span className='errorMessage'> {errors.email}</span>}
            { errors.password && <span className='errorMessage'> {errors.password}</span>}
          {errorPass && <span className='errorMessage'> Email or Password is invalid </span>}
        </div>
    </div>
    </>
  )
}

export default Login