import React, { useState } from 'react'
import Cardpost from '../../components/card/Cardpost';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import Slider from '../../components/Slider/Slider';

const Home = () => {

    const [isActive, setIsActive] = useState(true);

  return (
    <>

     {/* <Navbar  setIsActive = {setIsActive} isActive ={isActive}/> */}
     <Slider setIsActive = {setIsActive} isActive ={isActive}/>
     <Cardpost />
     {/* <Footer/> */}
    
    
    
    </>
  )
}

export default Home;