import React from 'react'
import Navbar from '../../components/navbar/Navbar'
import Projects from '../projects/Projects'

const Intro = () => {
  return (
    <div>
        {/* <Navbar /> */}
        <Projects/>
    </div>
  )
}

export default Intro