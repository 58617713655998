import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import "./projectcard.scss"


const ProjectCard = ({cardData}) => {
  return (
    <div className='cards'>

<Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image={cardData.img}
          alt="green iguana"
          className='img-card'
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          {cardData.cardTitle}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {cardData.cardDetails}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    </div>
  )
}

export default ProjectCard