import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: null,
        isFetching: false,
        error: false
    },
    reducers: {
        //Login users
        loginStart: (state) => {
            state.isFetching = true;
        },
        loginSuccess: (state, action) => {
            state.isFetching = false;
            state.currentUser = action.payload;
        },
        loginFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
         //CREATE USERS
         loginCreatedStart: (state) => {
            state.isFetching = true;
        },
        loginCreatedSuccess: (state, action)=> {
            state.isFetching = false;
        },
        loginCreatedFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        CreateLogOut: (state) => {
            state.currentUser = null;
        }

    }
});

export const {
    loginStart,
    loginSuccess,
    loginFailure,
    loginCreatedStart,
    loginCreatedSuccess,
    loginCreatedFailure,
    CreateLogOut
} = userSlice.actions;

export default userSlice.reducer