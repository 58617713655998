import React, { useEffect } from 'react';
import "./footer.scss";
import { Email, Facebook, LinkedIn, Map, Phone, Twitter } from '@mui/icons-material';
import  {Link, useLocation, useNavigate}  from "react-router-dom";
import Add from '../contact/contact';
import { useSelector } from 'react-redux';
const Footer = () => {

    const currentUser = useSelector(state => state.user.currentUser);
    const location = useLocation().pathname //.pathname.split('/')[2];
    const navigate = useNavigate()


    

    const handleRefresh =() => {
    }

    
    

  return (
    <div className='footerContainer'>
        <div className="footerWrapper">

            <div className="footerItemAbout">
                <div className="footerTitle">About Us</div>

                <span className='aboutSpan'>We believe in solving complex business challenges of the converging world, by using cutting-edge technologies.</span>
                <div className="icons">
                    <div className="iconItemList">
                        {/* <Facebook className='icon'/> */}
                        <a href='https://www.linkedin.com/company/themdsoft/' target="_blank" style={{ textDecoration: 'none', color:'inherit' }} >
                        <LinkedIn className='icon'/></a>

                        <a href='https://twitter.com/themdsoft' target="_blank" style={{ textDecoration: 'none', color:'inherit' }}>
                        <Twitter className='icon'/>
                        </a>
                    </div>
                </div>
            </div>

            <div className="footerItemLink">
            <div className="footerTitle">Links</div>

            <div className="quickLink">

            <div className="quickLinkList">
            <Link to="projects/automation" style={{ textDecoration: 'none', color:'inherit' }} >

                <div className="qiucklistItems">Automation</div>
                </Link>
            <Link to="projects/cloud" style={{ textDecoration: 'none', color:'inherit' }} >

                <div className="qiucklistItems">Cloud & Infrastructure</div></Link>
            
            <Link to="/projects/consulting" style={{ textDecoration: 'none', color:'inherit' }}  >

                <div className="qiucklistItems">IT Consulting</div>
                </Link>

                <Link to="/projects/application" style={{ textDecoration: 'none', color:'inherit' }} >  
                <div className="qiucklistItems">Application Management & Development</div>
                </Link>

                
            <Link to="/projects/data" style={{ textDecoration: 'none', color:'inherit' }} >
                
                <div className="qiucklistItems">Data & Reports</div>
                </Link>

            <Link to="/projects/outsourcing" style={{ textDecoration: 'none', color:'inherit' }} >
                <div className="qiucklistItems">IT Outsourced Services</div>
            </Link>
               
                
                
            </div>

            <div className="quickLinkList">
                {/* <div className="qiucklistItems">About</div> */}
                <Link to="" style={{ textDecoration: 'none' }} ></Link>
                {/* <div className="qiucklistItems">FAQ</div>
                <div className="qiucklistItems">Help</div>
                <div className="qiucklistItems">Terms & Conditions</div> */}
                {/* <div className="qiucklistItems">Contact</div> */}
                {/* <div className="qiucklistItems">About</div> */}
            </div>
                </div>
            </div>

            <div className="footerItemContact">
            <div className="footerTitle">Contact Us</div>

            <div className="contactusList">
                <div className="contact">
                    <Map className="contactIcon"/> 
                    <div className="contactAddress">
                        <span>San Fracisco, CA 94122</span>
                        
                    </div>
                </div>


                <div className="contact">
                    <Phone className="contactIcon"/>
                    <div className="contactAddress">
                        {/* <div className="phonecontact"><span>+78719781</span>  </div> */}
                        <div className="phonecontact"> <span> +1-415-928-9661</span>  </div>
                       
                        
                    </div>
                </div>

                <div className="contact">
                    <Email className="contactIcon"/>
                    <div className="contactAddress">
                        <span>support@themdsoft.com</span>
                    </div>
                </div>
                
            </div>
                
            </div>
            

               
                
            
         
        </div>
        <Add/>
        {currentUser && 
          ""
        }
         <a href="https://wa.me/+14159289661" class="whatsapp_float" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon" title='Chart with Us'></i></a>
        <hr className='hr'/>
       <div className="copyright">  © 2014 - 2023 TheMDsoft, Inc. All Rights Reserved.</div>  
    </div>
  )
}

export default Footer