const ServiceData = [
    {
      id: 1,
      title: "IT OUTSOURCING",
      subTitle: "Business disruption is not an option",
      shortwords: "IO",
      img:"https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/MAIN.jpg?alt=media&token=9254b90e-d020-46ab-b355-9b82248a841a",
      messages: "Count on TheMdsoft to provide mission-critical IT Outsourcing services reliably and securely, with a clear path to modernization.",
      service0: "Data Center Networking: Scalable and automated connectivity for virtualized compute, and storage" ,
      service1:  "Ecosystem of 200+ partners",
      service2: "Business focus across all industries",
      service3:"Mainframe modernization",
      service4: "" ,
      service5: "",
      service6: "",
      service7: "",
      expanded: true,
      serviceId: "outsourcing",
     
    },
    {
      id: 2,
      title: "APPLICATION MANAGEMENT & DEVELOPMENT",
      subTitle: "Monitor and manage mission-critical applications",
      shortwords: "AD",
      img: "https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/Application.jpg?alt=media&token=3e5ffe98-e53b-48d3-b8d1-4092848e31f4",
      messages:"Rich application experience enables high availability and continuous optimization in a hybrid cloud across the business application ecosystem.",
      service0: "" ,
      service1: "",
      service2: "Application monitoring and scalability configuration",
      service3: "Cloud resource monitoring and troubleshooting",
      service4: "Monthly usage reporting, forecast, and optimization" ,
      service5: "DevOps set-up and automation",
      service6: "",
      service7: "",
      expanded: true,
      serviceId: "application",
    },
    {
      id: 3,
      title: "CLOUD & INFRASTRUCTURE",
      subTitle: "Providing speed, scale, and consistency",
      shortwords: "CD",
      img: "https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/CloudComputing.jpg?alt=media&token=211d3a4b-da4a-45d8-8e8d-644a3168ee36",
      messages:"Cloud is quite a buzzword these days. From business to personal activities, it is becoming an integral part of life. Whether you open a document on Google Docs or store your photos on iCloud or Google Drive, you are deploying the cloud.",
      service0: "" ,
      service1: "",
      service2: "",
      service3: "",
      service4: "" ,
      service5: "",
      service6: "",
      service7: "",
      expanded: true,
      serviceId: "cloud",

    },
    {
      id: 4,
      title: "AUTOMATION",
      subTitle: "Digital Process Automation",
      shortwords: "A",
      img: "https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/automation.jpeg?alt=media&token=7c45ae1a-b9a7-420d-8d7d-016ec2851847",
      messages:"Intelligent digital process automation: Optimizing and streamlining processes is essential for cost take out and creating sustained business advantage. Process consulting services help understand, model, and streamline processes, understand optimization opportunities and select the right products to digitize the process, automate and enable AI-based decisions.",
      service0: "Facial Recognition time Clocking System" ,
      service1: "Intelligent automation",
      service2: "",
      service3: "",
      service4: "" ,
      service5: "",
      service6: "",
      service7: "",
      expanded: true,
      serviceId: "automation",

    },
    {
      id: 5,
      title: "DATA & REPORTS",
      subTitle: "Database Management System & Reports",
      shortwords: "DR",
      img: "https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/data.jpg?alt=media&token=2c855373-2f44-4baf-9106-9c2dbb924230",
      messages:"A database management system (or DBMS) is essentially nothing more than a computerized data-keeping system",
      service0: "" ,
      service1: "Database Management System Management",
      service2: "Application & Automation Reports generations",
      service3: "Automated data preparation and Reports",
      service4: "" ,
      service5: "",
      service6: "",
      service7: "",
      expanded: true,
      serviceId: "data",

    },
    {
        id: 6,
        title: "IT CONSULTING", 
        subTitle: "Transformation of Business and Technology",
        shortwords: "IC",
        img: "https://firebasestorage.googleapis.com/v0/b/themdsoft-b6bd4.appspot.com/o/consulting.png?alt=media&token=88a1eae7-800f-4699-bd15-73aaab44b16c",
        messages:"We drive growth by solving complex business problems swiftly via a design-centric, agile, and platform-driven method. Our technology consulting services enable us to deliver customer experience transformation by solving complex problems to enable growth, innovation, and value, and help make AI pervasive with data platforms and intelligence at the intersection of design thinking and data sciences.",
        service0: "Data & Intelligent" ,
        service1: "Core Modernazations",
        service2: "",
        service3: "",
        service4: "" ,
        service5: "",
        service6: "",
        service7: "",
        expanded: true,  
      serviceId: "consulting",

    }
  
    
  ];
  
  export default ServiceData;