import { configureStore, combineReducers } from "@reduxjs/toolkit";

import userReducer from './userRedux';


// using redux persist help the app to store redux data in local Storage even if the page is refresh...
//  package which must be install to get persis working is "redux-persist"
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'

  import storage from 'redux-persist/lib/storage';
  // ordinary redux

  const persistConfig = {
    key: "root",
    version: 1,
    storage,
    
  };

const rootReducer = combineReducers({user: userReducer})

//PASSING USER REDUCERS TO THE PERSIST
const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store =  configureStore({
    reducer: persistedReducer,
    
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
// using redux persist help the app to store redux data in local Storage even if the page is refresh...

 export let persistor = persistStore(store)