const SideMenuReducer = (state, action) => {

    switch (action.type) {
        case "OFF": {
            return {
                sideMenu: false,
            }
        }
        case "ON": {
            return {
                sideMenu: true,
            };
        }
        case "TOGGLE": {
            return {
                sideMenu: !state.sideMenu,
            };
        }
        default:
            return state;
    }
};

export default SideMenuReducer;