import { v4 as uuidv4 } from "uuid";

const dataSlider = [
  {
    id: uuidv4(),
    title: "AUTOMATION",
    subTitle: "Digital Process Automation",
    servicePath: "/projects/automation"
  },
  {
    id: uuidv4(),
    title: "CLOUD & INFRASTRUCTURE",
    subTitle: "Cloud transformation platform providing speed, scale, and consistency",
    servicePath: "/projects/cloud"
  },
  {
    id: uuidv4(),
    title: "APPLICATION MANAGEMENT & DEVELOPMENT",
    subTitle: "Monitor and manage mission-critical applications",
    servicePath: "/projects/application"
  },
  {
    id: uuidv4(),
    title: "IT OUTSOURCING",
    subTitle: "Business disruption is not an option",
    servicePath: "/projects/outsourcing"
  },
  {
    id: uuidv4(),
    title: "DATA & REPORTS",
    subTitle: "Database Management System & Reports",
    servicePath: "/projects/data"
  },
  {
    id: uuidv4(),
    title: "IT CONSULTING",
    subTitle: "Transformation of Business and Technology",
    servicePath: "/projects/consulting"
  },
];

export default dataSlider;
