import React, { useState } from 'react'
import { omit } from 'lodash';



const ContactsFormValidation = (ContactsCallBack) => {
    const [errors, setErrors] = useState({})
    const [errorPass, setErrorPass] = useState(false);
    const [values, setValues] = useState({
        isAdmin: true,
    });
    const [country, setcountry] = useState("")
    const [industry, setindustry] = useState("")
    const [services, setServices] = useState("")
    const [refoption, setRefOption] = useState("")
    // const [AllData, setAllData] = useState({})
 
const validate = (e, name, value) => {
    //  A FUNCTION TO VALIDATE EACH INPUT VALUES
    console.log(value)
    switch (name) {
        case 'fullname':
            if(value.length <= 5){
                // we will set the error state

                setErrors({
                    ...errors,
                    fullname:'Fullname atleast have 5 letters'
                })
            }else{
                
                let newObj = omit(errors, "fullname");
                setErrors(newObj);
                
            }
            break;
        case 'email':
              if (
                !new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)
                ) {
                setErrors({
                    ...errors,
                    email: 'Provide valid email'
                })
              }else{

                let newObj = omit(errors, "email");
                setErrors(newObj);
                
            }
            break;
            case 'contact':
                if (
                  !new RegExp(/^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/).test(value)
                  ) {
                  setErrors({
                      ...errors,
                      contact: 'Provide valid contact'
                  })
                }else{
  
                  let newObj = omit(errors, "contact");
                  setErrors(newObj);
                  
              }
              break;
        case 'company':
            if(value.length <= 8){
                // we will set the error state

                setErrors({
                    ...errors,
                    company:'company name atleast have 8 letters'
                })
            }else{
                
                let newObj = omit(errors, "company");
                setErrors(newObj);
                
            }
            break;
        case 'message':
            if(value.length <= 8){
                // we will set the error state

                setErrors({
                    ...errors,
                    message:"minimum 10 words"
                })
            }else{
                
                let newObj = omit(errors, "message");
                setErrors(newObj);
                
            }
            break;

    
        default:
            break;
    }
};

const HandleChange = (e) => {
    //Prevent default if is any
    e.preventDefault();
    let name =  e.target.name;
    let value = e.target.value;

    validate(e, name, value);

    setValues({
        ...values,
        [name]: value
    }) ;

    setErrorPass(false);

   

    
    
}

// const HandleSubmit = (e) => {
//    if(e) errors.preventDefault(e);

// ;
//     if(Object.keys(errors).length === 0) {
//            LoginCallBack()
//     }else {

//     }
// }


  return {
    values,
    errors,
    HandleChange,
    errorPass, 
    setErrorPass,
    country, 
    setcountry,
    industry, 
    setindustry,
    services, 
    setServices,
    refoption, 
    setRefOption,
    
  }
}

export default ContactsFormValidation