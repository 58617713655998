
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// const firebaseConfig = {
//   apiKey: "AIzaSyDz45VqL7yXLMkyDnQE-QpeSsoBhcqM-Mo",
//   authDomain: "dashboard-87588.firebaseapp.com",
//   projectId: "dashboard-87588",
//   storageBucket: "dashboard-87588.appspot.com",
//   messagingSenderId: "1025664337785",
//   appId: "1:1025664337785:web:f1c64e18f9d399ffed51a8"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBcCLMd0MeiSRkYDMw6JHu7olnrxb6gR9Q",
  authDomain: "themdsoft-b6bd4.firebaseapp.com",
  projectId: "themdsoft-b6bd4",
  storageBucket: "themdsoft-b6bd4.appspot.com",
  messagingSenderId: "931198318023",
  appId: "1:931198318023:web:3ec0ccef2778ff1e5955e2"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);