import React, {useEffect, useState} from 'react';
import './Slider.css';
import BtnSlider from './BtnSlider';
import dataSlider from './dataSlider';
import { useContext } from 'react';
import { MenuContext} from '../../context/SideMenuContext';
import { Link, useNavigate } from 'react-router-dom';
// import img1 from '../../imgs/img1.jpg'
// import Button from '@mui/material/Button';

const Slider = ({isActive, setIsActive}) => {

    const [slideIndex, setSlideIndex] = useState(1)
    const  {dispatch,sideMenu} = useContext(MenuContext);
    const [passAlt, setpassAlt] = useState("")

    const navigate = useNavigate()
    


    const nextSlide = () => {
        if(slideIndex !== dataSlider.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === dataSlider.length){
            setSlideIndex(1)
        }
    }
// MAKE IT SLOW
    setTimeout(nextSlide, 0.7 * 10000);




    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(dataSlider.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index);
    }

    const HangleNavigate = (paths) => {
        switch (paths) {
            case 1:
                navigate("/projects/automation")
                break;
            case 2:
                navigate("/projects/cloud")
                break;
            case 3:
                navigate("/projects/application");
            break;
            case 4:
                navigate("/projects/outsourcing")
                break;
            case 5:
                navigate("/projects/data")
                break;
            case 6:
                navigate("/projects/consulting")
                break;
        
            default:
                break;
        }
    
    };


   useEffect(() => {
     
   
    switch (slideIndex) {
        case 1:
            setpassAlt("Automation")
            break;
        case 2:
           
            setpassAlt("Cloud and infrastructure")
            break;
        case 3:
           
            setpassAlt("Application development")
        break;
        case 4:
           
            setpassAlt("IT Outsourcing")
            break;
        case 5:
          
            setpassAlt("Data and Reports")
            break;
        case 6:
           
            setpassAlt("IT Consulting")
            break;
    
        default:
            break;
    }

   }, [slideIndex])
   

// console.log(img1)

    return (
        <div className="container-slider"
        // style={{zIndex: sideMenu ? '0':'-1'}}
        >
            {dataSlider.map((obj, index) => {
                return (
                    <div
                    key={obj.id}
                    className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
                    >
                        <img 
                        src={process.env.PUBLIC_URL + `/imgs/img${index + 1}.jpg`} 
                        alt={passAlt}
                        onClick={()=>HangleNavigate(slideIndex)}
                        />
                        
                        <div className="containerTitle">
                            <div className="title">{obj.title}</div>
                            <div className="subtitle">{obj.subTitle}</div>
                           
                        </div>
                    </div>
                )
            })}
            <BtnSlider moveSlide={nextSlide} direction={"next"}  />
            <BtnSlider moveSlide={prevSlide} direction={"prev"}  />

            <div className="container-dots">
                {Array.from({length: 6}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                ))}
            </div>
        </div>
    )
}

export default Slider;
