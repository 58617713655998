import React, { useState } from 'react'
import ProjectCard from '../../components/projectcard/ProjectCard';
import "./projects.scss";
import  ServicePage  from "./ProjectService";

import { collection, getDocs, query, where} from 'firebase/firestore'
import {db} from '../../firebase'
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';



const Projects = () => {

  const [allData, setAllData] = useState([]);

  const location = useLocation().pathname.split('/')[2]
  



  useEffect(()=>{
      const fetchedData = async () => {
        // let list = [];
        // try {

        //   const querySnapshot = await getDocs(collection(db, "services"));
        // querySnapshot.forEach((doc)=> {
        //   // push data into the List
        //   list.push({ id: doc.id, ...doc.data()});
        // });
        // setAllData(list)
        // // console.log(list)
        // } catch (err) {
        //   console.log(err)
        // }
        
      }
      fetchedData();
  },[]);


  useEffect(()=>{

    const fetchedData = async () => {
      // console.log(location)
      let list = [];
      try {
        const getServices = query(collection(db, "services"), 
        where("serviceId", "==", location)
        );
        const queryService = await getDocs(getServices)
        queryService.forEach((doc)=> {
          // push data into the List
          list.push({ id: doc.id, ...doc.data()});
        });
        setAllData(list)
      } catch (err) {
        console.log(err)
      }
      
    }
    fetchedData();
},[location]);

  // console.log(allData)

  return (

   <>
    {allData.map((cardData)=>(
          
          
    <div className='projects' key={cardData.id}>
        {/* <h1 className='projectsTitle'>Here some of the projects</h1> */}
        
        <div className='project' >
                

                    <div className="card">
                       
                          <ProjectCard cardData = {cardData}/>
                        
                    </div>
                    <div className="projectDetails">

                      
                      <h1 className="projecTitle">{cardData.cardTitle} </h1>
                      
                      <div className="projectDesc">
                        {/* <div className="desc"></div> */}
                              <div className="desc_1">
                              {cardData.servicesDetails1}
                              </div>
                              <div className="desc_2">
                              {cardData.servicesDetails2
}
                              </div>

                        
                        
                      </div>
                        {/* <button className="projectButton">
                        <Link to="/contacts" style={{ textDecoration: 'none', color:'inherit' }} >

                          Contact us
                          </Link>
                          </button> */}
                      
                      
                    </div>

               </div>   
           </div>      

          ))} 
           
   </> 

 





    
  )
}

export default Projects