import React, { useState } from 'react'
import { omit } from 'lodash';



const LoginFormvalidation = (LoginCallBack) => {
    const [errors, setErrors] = useState({})
    const [errorPass, setErrorPass] = useState(false);
    const [values, setValues] = useState({});

const validate = (e, name, value) => {
    //  A FUNCTION TO VALIDATE EACH INPUT VALUES
    switch (name) {
        case 'email':
              if (!new RegExp( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value)) {
                setErrors({
                    ...errors,
                    email: ''
                })
              }else{

                let newObj = omit(errors, "email");
                setErrors(newObj);
                
            }
            break;
        case 'password':
            if(
                !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
            ){
                setErrors({
                    ...errors,
                    password:''
                })
            }else{

                let newObj = omit(errors, "password");
                setErrors(newObj);
                
            }
            break
    
        default:
            break;
    }
};

const HandleChange = (e) => {
    //Prevent default if is any
    e.persist();
    let name =  e.target.name;
    let value = e.target.value;

    validate(e, name, value);

    setValues({
        ...values,
        [name]: value
    }) ;

    setErrorPass(false);
    
}

const HandleSubmit = (e) => {
   if(e) errors.preventDefault(e);

;
    if(Object.keys(errors).length === 0) {
           LoginCallBack()
    }else {

    }
}


  return {
    values,
    errors,
    HandleChange,
    HandleSubmit,
    errorPass, 
    setErrorPass
  }
}

export default LoginFormvalidation