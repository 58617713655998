import React, { useState } from 'react';
import "./contacts.scss";
import { collection, addDoc, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ContactsFormValidation from '../../Hooks/ContactsFormValidation';
import { useNavigate , useEffect } from 'react-router-dom';
import Select from "react-select";

const Contacts = () => {
  const [registerData,setRegisterData] = useState({
    // isAdmin: false
  });
  const {HandleChange, values, errors, 
    errorPass, setErrorPass, country, 
    setcountry,
    industry, 
    setindustry,
    services, 
    setServices,
    refoption, 
    setRefOption,
    
  
  } = ContactsFormValidation();
  const [error, setError] = useState();
  // const [country, setcountry] = useState("")
  // const [industry, setindustry] = useState("")
  // const [services, setServices] = useState("")
  // const [refoption, setRefOption] = useState("")



  const navigate = useNavigate();

  const options = [
    { value: "none", label: "Empty" },
    { value: "United State", label: "United State" },
    { value: "Canada", label: "Canada" },
    { value: "West Africa", label: "West Africa" },
    { value: "South Africa", label: "South Africa" },
    
  ];

  const optionIndustry = [
    { value: "none", label: "Empty" },
    { value: "BANKING & FINANCE", label: "BANKING & FINANCE" },
    { value: "ENERGY", label: "ENERGY" },
    { value: "HEALTH", label: "HEALTH" },
    { value: "TRAVEL. TRANSPORT & HOSPITALITY ", label: "TRAVEL. TRANSPORT & HOSPITALITY " },
    { value: "MANUFACTURING", label: "MANUFACTURING" },
    { value: "HI TECH", label: "HI TECH" },
    
  ];

  const optionServices = [
    { value: "none", label: "Empty" },
    { value: "Automation", label: "Automation" },
    { value: "cloud", label: "Cloud" },
    { value: "consulting", label: "consulting" },
    { value: "Infrastructural Management Services (IMS)", label: "Infrastructural Management Services (IMS)" },
    { value: "Application Management", label: "Application Management" },
    { value: "Data & Analytics", label: "Data & Analytics" },
    { value: "Database Management System", label: "Database Management System"},
    { value: "IT Outsourced Services", label: "IT Outsourced Services"},
  ];

  const optionRef = [
    { value: "none", label: "Empty" },
    { value: "Search Engine", label: "Search Engine" },
    { value: "Sales Person", label: "Sales Person" },
    { value: "Trade show / Conferences", label: "Trade show / Cobferences" },
    { value: "Cooperate connect", label: "Cooperate connect" },
    { value: "Others", label: "Others" }, 
  ];


  const handleCountrySelect = (e) => {
    // e.preventDefault();
    setcountry(e.value);
    // HandleChange();
   
  }

  const handleIndustrySelect = (e) => {
    // e.preventDefault();
    setindustry(e.value);
    // HandleChange();
    
  }

  const handleServicesSelect = (e) => {
    // e.preventDefault();
    setServices(e.value);
    // HandleChange();
  }
  const handleRefSelect = (e) => {
    // e.preventDefault();
    setRefOption(e.value);
    // HandleChange();
  }

  // const HandleData = (e) => {
  //     setRegisterData(prev => {
  //       return{...prev, [e.target.name]: e.target.value}
  //     })
  // };

  const HandleRegister = async(e) => {
    e.preventDefault();

    if(Object.keys(errors).length === 0 && Object.keys(values).length > 5) {
    try {
    //   const res = await createUserWithEmailAndPassword (
    //     auth,
    //     registerData.email,
    //     registerData.password
    //     // navigate("/")
    //   );

      await addDoc(collection(db, "contact"),{
        ...values,
        country: country,
        industry: industry,
        services: services,
        referred: refoption,
        timeStamp: serverTimestamp(),
        
      });
      
      navigate("/");
      setErrorPass(false);

    } catch (err) {
      // console.log("duplicated email");
      setErrorPass(false)
      // console.log(err)
     

    }
}  else {
  setErrorPass(true);
}                 //HANDLE REGISTER


  }
// console.log(registerData, country, industry, services, refoption)
console.log(values )
return (
    <div className="mainContainer">

    
    
    <div className='contactsContainer'>
       <div className="wrapper">
         <div className="title"> <h1>CONTACT US</h1> </div>
         <div className="errorMessage"> {errors.fullname && <span > {errors.fullname}</span>} </div>
         <div className="errorMessage"> {errors.email && <span > {errors.email}</span>} </div>
         <div className="errorMessage"> {errors.company && <span > {errors.company}</span>} </div>
         <div className="errorMessage"> {errors.message && <span > {errors.message}</span>} </div>
         <div className="errorMessage"> {errors.contact && <span > {errors.contact}</span>} </div>
         <div className="errorMessage"> {errorPass && <span > Contact details is invalid </span>} </div>
          
         <form >
           
           <input name="fullname" type="text" placeholder='Full Name' onChange={HandleChange}/>
           {/* <input name="lastname" type="text" placeholder='Last name' onChange={HandleData}/> */}
           <input name="email" type="text" placeholder='Email' onChange={HandleChange}/>
           <input name="contact" type="text" placeholder='x-xxx-xxx-xxxx' onChange={HandleChange}/>
           {/* <input name="referred" type="text" placeholder='Referred By' onChange={HandleData}/> */}
           <input name="company" type="text" placeholder="Your Company's Name" onChange={HandleChange}/>
           {/* <input name="confirmpassword" type="password" placeholder='Confirm password' onChange={HandleData}/> */}
           
           <Select
        options={options}
        onChange={handleCountrySelect}
        value={options.filter(function(option) {
          return option.value === country;
        })}
        label="Country"
        className='select'
        placeholder='Country'
        name='country'
        
      />

     <Select
        options={optionIndustry}
        onChange={handleIndustrySelect}
        value={optionIndustry.filter(function(optionind) {
          return optionind.value === industry;
        })}
        label="Industry"
        className='select'
        placeholder='Industry'
        
      />

      <Select
        options={optionServices}
        onChange={handleServicesSelect}
        value={optionServices.filter(function(optionServices) {
          return optionServices.value === services;
        })}
        label="services"
        className='select'
        placeholder='Services'
        
      />

<Select
        options={optionRef}
        onChange={handleRefSelect}
        value={optionRef.filter(function(optionRef) {
          return optionRef.value === refoption;
        })}
        label="services"
        className='select'
        placeholder="Referred by"
        
      />

    <textarea name="message" 
    rows={4} placeholder='Let us know how we can help you today' 
    onChange={HandleChange}
    />
         
         
         
         <span className="plicyagreement">
                   By contacting us, I consent to the processing of my personal
                   data in accordance with the <b> PRIVACY POLICY </b>
         </span>
        
          <button onClick={HandleRegister}>SUBMIT</button>
      
          
         </form>
         {/* {!error ? console.log("Processig...") : <span className='error'> Something went wrong</span>} */}
         {errorPass && <span className='error'> Something went wrong</span>}
       
       </div>
        </div>
        </div>
  )
}

export default Contacts;