import React, { useState } from 'react';
import { AddShoppingCart, AddShoppingCartOutlined, AlternateEmail, ContactSupport, Email, ExpandMore, Favorite, FavoriteBorder, MailOutline, MoreVert} from '@mui/icons-material'
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Checkbox,  Collapse,  IconButton, Typography, Box } from '@mui/material'
import styled from '@emotion/styled';
import "./Cardpost.scss";
import  ServiceData  from "./ServiceData";
import { Link } from 'react-router-dom';

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

const Cardpost = () => {

  const [expanded, setExpanded] = React.useState(false);
  const [cardID, setCardID] = useState("");
  const [passServiceId, setPassServiceId] = useState("");
  const [addcart, setAddcart] = useState(true)

  const handleExpandClick = (e, id) => {
    setExpanded(!expanded);
    // console.log(id)
    // console.log(e)

  };
  const HandlePassData = (data) => {
  //  e.preventDefault()
    setPassServiceId(data)
    // console.log(passServiceId)
    // console.log(data)
  }


// console.log(passServiceId)
  return (
    <Box>
    <div className='cardContainer'>

      {ServiceData.map((dataservice)=>{

          return (

        
        
          <Card sx={{margin:"5px"}} key= {dataservice.id} className="CardHolder">
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "lightblue" }} aria-label="recipe" className='avatarholder'>
            {dataservice.shortwords}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            {/* <MoreVert /> */}
          </IconButton>
        }
        title={dataservice.title}
        subheader={dataservice.subTitle}
      />
      <CardMedia
      className='imgcontainer'
        component="img"
        height="194"
        image={dataservice.img}
        alt={dataservice.title}
        sx={{objectFit:"cover"}}
      />
      <CardContent sx={{height: "200px"}}>
        <Typography variant="body2" color="text.secondary">
         {dataservice.messages}

            <ul className='ulservers'>
            
              {dataservice.service0 !== "" &&  <li> {dataservice.service0} </li>}
              {dataservice.service1 !== "" &&  <li> {dataservice.service1} </li>}
              {dataservice.service2 !== "" &&  <li> {dataservice.service2} </li>}
              {dataservice.service3 !== "" &&  <li> {dataservice.service3} </li>}
              {dataservice.service4 !== "" &&  <li> {dataservice.service4} </li>}
              {dataservice.service5 !== "" &&  <li> {dataservice.service5} </li>}
              {dataservice.service6 !== "" &&  <li> {dataservice.service6} </li>}
              {dataservice.service7 !== "" &&  <li> {dataservice.service7} </li>}
           
            </ul>
         
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
            {!addcart && 
         <Checkbox  icon={<AddShoppingCartOutlined />} checkedIcon={<AddShoppingCartOutlined sx={{color:"red"}} />} />
           }
        </IconButton>
        <Link to="/contacts"> 
        <IconButton aria-label="share">
          
          <Checkbox  icon={<MailOutline />} checkedIcon={<MailOutline sx={{color:"red"}}  className='support' />} />
            
        </IconButton>
           </Link>
       {/* /${passServiceId} */}
       <Link to={`/projects/${dataservice.serviceId}`} style={{textDecoration:"none", color:"inherit"}}>
        <Button 
        //  expand={expanded}
         onClick={(e) => HandlePassData()} 
        //  aria-expanded={expanded}
        //  aria-label="show more"
        className='Learnbutton'
        
        size="small">Learn More About {dataservice.serviceId}</Button>
        </Link>
    
      </CardActions>

      <Collapse in={dataservice.id === cardID ? dataservice.expanded : ""} timeout="auto" unmountOnExit >
        <CardContent >
          {/* key={dataservice.id} */}
          <Typography paragraph>Services Include:</Typography>

          <Typography paragraph>
            <ul className='ulservers'>
            
              {dataservice.service0 !== "" &&  <li> {dataservice.service0} </li>}
              {dataservice.service1 !== "" &&  <li> {dataservice.service1} </li>}
              {dataservice.service2 !== "" &&  <li> {dataservice.service2} </li>}
              {dataservice.service3 !== "" &&  <li> {dataservice.service3} </li>}
              {dataservice.service4 !== "" &&  <li> {dataservice.service4} </li>}
              {dataservice.service5 !== "" &&  <li> {dataservice.service5} </li>}
              {dataservice.service6 !== "" &&  <li> {dataservice.service6} </li>}
              {dataservice.service7 !== "" &&  <li> {dataservice.service7} </li>}
           
            </ul>
          </Typography>
          
        </CardContent>
      </Collapse>
      
    </Card> 
    )})}
    </div></Box>
  )
}

export default Cardpost;