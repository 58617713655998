import React, { useState } from 'react';
import "./navbar.scss"
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Badge from '@mui/material/Badge';
import { Menu } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Add from '../contact/contact';
import { useDispatch, useSelector } from 'react-redux';
import { CreateLogOut } from "../../redux/userRedux";
import { useContext } from 'react';
import { MenuContext} from '../../context/SideMenuContext';



const Navbar = ({isActive, setIsActive}) => {
    const [user, setUser] = useState(false);
    const [serviceAdmin, setServiceAdmin] = useState(true);
    const dispatchRedux = useDispatch();
    const  {dispatch,sideMenu} = useContext(MenuContext)

    const currentUser = useSelector(state => state.user.currentUser);

    const handleLogout = () => {
        dispatchRedux(CreateLogOut())
        // dispatch({type:"TOGGLE", sideMenu})
    }

//   useState is in App.js for global state
        const HandleClick = () => {
            // setIsActive(!isActive);
            dispatch({type:"TOGGLE", sideMenu})

        }
    
// console.log(sideMenu)
  return (
      <>
      <div className="menu-btn">
      <Menu onClick={HandleClick}/>
      </div>
    <div className='navContainer'>
        <nav className='mainNav'>
            <Link  to='/' style={{textDecoration: "none", color:"inherit"}}>
            <ul className='logo'>
                <li> TheMDsoft</li>
            </ul>
            </Link>
            <ul className={
                sideMenu ? 'mainmenue' : 'mainmenue show'}
                // style={{zindex: isActive ? '0':'3'}}
                >
                <li> <a href="/" style={{zIndex: "0!important"}}>HOME</a></li>
               <Link to="/contacts"> <li> <a target="_blank">CONTACT</a></li></Link>
               <Link to="/"  onClick={handleLogout}>{currentUser &&  <li> <a>LOGOUT</a></li> }</Link> 


               {/* <Link to="/login">{!currentUser && <li> <a  target="_blank">LOGIN</a></li> }</Link>  */}
            
               {/* <Link to="/register">{!currentUser && <li> <a  target="_blank">REGISTER</a></li> }</Link>  */}
               {/* <Link to="/services">{ serviceAdmin && <li> <a target="_blank">REGISTER SERVICES</a></li> }</Link>  */}

            </ul>

            <ul className="rightmenu">
                {currentUser && 
            <li> <a  target="_blank">
               
            <Badge badgeContent={4} color="error">
              <ShoppingCartOutlinedIcon className='icon'/>
            </Badge> </a></li>  
            
            } 

           
            </ul>
        </nav>
    </div>
    </>
  )
}

export default Navbar