import { Avatar, Box, Button, ButtonGroup, Fab, Modal, Stack, styled, TextField, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { DateRange, EmojiEmotions, InsertPhoto, MailOutline, PersonAdd, VideoCameraBack } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
const Add = () => {
    const [open, setOpen] = useState(false);
    const navigators = useNavigate()

    const ModalStyle = styled(Modal)({
        display: "flex",
        justifyContent:"center",
        alignItems:"center"
       
    });

    const UserBox = styled(Box)({
        display: "flex",
        alignItems:"center",
        gap:"10px",
        marginBottom:"20px"
       
    });

const HandleContact = () => {
   navigators("/contacts")
}




  return (
    <>

    {/* TOOLTIP */}{/* FAB components */}

    {/* <Link></Link> */}
        <Tooltip 
        // onClick={(e)=>setOpen(true)}
        onClick={HandleContact}
        title="Email Us" sx={{
          position: "fixed",
          bottom: 130,
          right: { xs: "calc(50% - 25px)", md: 40 },
        }}
        >
  
      
        <Fab color="primary" aria-label="add">
        <MailOutline />
        </Fab>
  
     </Tooltip>


     {/* MODAL */}
     <ModalStyle
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box width={400} height={290} 
        bgcolor={"background.default"} 
        color={"text.primary"} p={3} 
        borderRadius={5
        }>
         <Typography variant='h6' color="gray" textAlign="center">Contact Us</Typography>
         {/* <UserBox>
             <Avatar
                 src = "https://firebasestorage.googleapis.com/v0/b/dashboard-87588.appspot.com/o/1650297147790WeChat%20Image_20220305210011.jpg?alt=media&token=bf1bbc5c-808b-4b44-8697-fe132e1bfcb8"
                 sx={{width:30, height:30}}
             />
             <Typography variant='span' fontWeight={500}>Moses Darko</Typography>
         </UserBox> */}

        <TextField id="standard-basic" label="Email" variant="standard" sx={{width:"100%"}}/>

        <TextField id="standard-basic" label="Phone Number" variant="standard" sx={{width:"100%"}}/>

         <TextField
          id="outlined-multiline-static"
          sx={{width:"100%"}}
          multiline
          rows={4}
          placeholder="Descrption of your project or register and login, and chart with us directly"
          variant='standard'
        />
        {/* <Stack direction="row" gap="10px" mt={2} mb={3}>
            < EmojiEmotions color='secondary'/>
            < InsertPhoto color='primary'/>
            < VideoCameraBack color='success'/>
            < PersonAdd color='error'/>
        </Stack> */}


        <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth>
        <Button>Submit</Button>
        {/* <Button sx={{width:"100px"}}><DateRange/></Button> */}
        
        </ButtonGroup>



        </Box>
      </ModalStyle>
    </>
  )
}

export default Add