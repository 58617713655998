
import { useState } from "react";
import Card from "./components/card/Cardpost";
import Footer from "./components/footer/Footer";
import Navbar from "./components/navbar/Navbar";
import Slider from "./components/Slider/Slider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Add from "./components/contact/contact";
import Contacts from "./components/contacts/Contacts";
import Intro from "./pages/intro/Intro";
import Services from "./pages/services/Services";
function App() {
   const [isActive, setIsActive] = useState(true);
  return (
    // <div className="App">
    //  <Navbar  setIsActive = {setIsActive} isActive ={isActive}/>
    //  <Slider setIsActive = {setIsActive} isActive ={isActive}/>
    //  <Card />
    //  <Footer/>
    // </div>
<BrowserRouter>

    
    <Navbar/>
       <Routes>
         <Route  path="/" >

         <Route index element={<Home/>} />
         <Route path="login" element={<Login/>} />
         {/* <Route path="register" element={<Register />} /> */}
         <Route path="contact" element={<Add/>} />
         <Route path="projects/:id" element={<Intro/>} />
         {/* <Route path="services" element={<Services/>} /> */}
         <Route path="contacts" element={<Contacts/>} />
        </Route>

       </Routes>

       <Footer/>
    </BrowserRouter>
  );
}

export default App;
